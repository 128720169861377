p {
  margin: 0;
}

.highlight {
  color: #007BFF;
  font-weight: bold;
}

.headerIntroduction p {
  transition: color 0.3s ease;
}

.background {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 100%;
  height: 100vh;
  height: 100dvh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background: linear-gradient(to bottom,
      #373640 13%,
      #4A3265 46%,
      #794A8F 77%,
      #9E9E9E 100%);
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  background-color: #242020;
}

.headerContent {
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  height: 90px;
  align-items: center;
}

.headerIntroduction p:hover {
  color: #C51DCE;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  text-shadow: 0 0 10px rgba(146, 22, 153, 0.8), 0 0 20px rgba(146, 22, 153, 0.6);
  cursor: pointer;
}

.headerIntroduction {
  display: flex;
  font-family: 'Aldrich', sans-serif;
  font-size: 24px;
  gap: 20px;
  color: white;
}

.headerName {
  font-family: 'Aldrich', sans-serif;
  display: flex;
  color: #C51DCE !important;
  font-size: 24px;
  align-items: center;
  gap: 20px;
}

.color {
  color: #C51DCE;
}

.LogoImg {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.headerName:hover {
  cursor: pointer;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  text-shadow: 0 0 10px rgba(146, 22, 153, 0.8), 0 0 20px rgba(146, 22, 153, 0.6);
}

.headerName a {
  display: contents;
  text-decoration: none;
  color: #C51DCE;
}

@media (max-width: 1440px) {
  .headerName {
    padding-right: 10px;
  }

  .headerContent {
    padding-left: 10px;
  }
}

@media (max-width: 850px) {
  .headerName {
    font-size: 16px;
  }

  .headerIntroduction {
    font-size: 16px;
  }
}

@media (max-width: 630px) {
  .headerName {
    font-size: 12px;
  }

  .headerIntroduction {
    font-size: 12px;
  }

  .LogoImg {
    width: 40px;
    height: 40px;
  }

  .header {
    height: 70px;
  }

  .headerContent {
    height: 70px;
  }
}

@media (max-width: 500px) {
  .headerName {
    font-size: 10px;
    gap: 5px;
  }

  .headerIntroduction {
    font-size: 10px;
    gap: 10px;
  }

  .LogoImg {
    width: 40px;
    height: 40px;
  }

  .header {
    height: 70px;
  }

  .headerContent {
    height: 70px;
  }
}