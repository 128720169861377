.ContactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 600px;
    justify-content: center;
    padding-bottom: 160px;
    padding-top: 100px;
}

.Info {
    margin-bottom: 10px;
}

.Info p {
    font-size: 36px;
    text-align: center;
}

.lineContainerContact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 700px;
}

.lineContact {
    width: 5px; 
    height: 400px; 
}

.leftContact {
    background: linear-gradient(to top, transparent, rgb(255, 255, 255), transparent);
}

.rightContact {
    background: linear-gradient(to top, transparent, rgb(255, 255, 255), transparent);
}
.content {
    height: 400px;
    width: 500px;
    text-align: center;
}
.content p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 5px;
}
.content a{
    color: white;
    text-decoration: underline;
}
.content img {
    margin-top: 50px;
    margin-bottom: 50px;
}
.Resume{
    color: black;
    margin-top: 20px;
    font-size: 36px !important;
}

