.maincontent {
  width: 100%;
  max-width: 1440px;
  flex-grow: 1 !important;
  height: 100%;
  align-self: center;
  font-family: 'Aldrich', sans-serif;
  color: white;
  padding-top: 100px;
}
.gitlinked{
  display: flex;
    flex-direction: row-reverse;
    margin-right: 50px;
    gap: 20px;
    margin-bottom: 5px;
}
.introductionText {
  width: 620px;
  height: 367px;
  font-size: 36px;
}

.introduction {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between !important;
  margin-bottom: 60px;
}

.HeaderIntText {
  color: #C51DCE;
  margin-bottom: 36px;
}

.introductionImg {
  margin-top: 50px;
}

.LogoImgIntroduction {
  width: auto;
  height: 380px;
  margin-right: 20px;
}

.techStack {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 70px;
}

.TechImg {
  height: 80px;
  width: auto;
}

.lineContainer, .Line1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.Line1{
  margin-top: 20px;
}
.line {
  height: 2px;
  width: 30%;
}

.left {
  margin-left: 50px;
  background: linear-gradient(to right, rgb(255, 255, 255), transparent);
}

.right {
  margin-right: 50px;
  background: linear-gradient(to left, rgb(255, 255, 255), transparent);
}

@media (max-width: 1440px) {
  .introduction {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 1300px) {
  .techStack {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 40px;
  }
}

@media (max-width: 1060px) {
  .techStack {
    display: block;
    text-align: -webkit-center;
  }

  .introductionText {
    font-size: 24px;
    width: 460px;
  }

  .introduction {
    align-items: center;
    margin-bottom: 50px;
  }

  .introductionImg {
    margin-top: 0px;
  }
}

@media (max-width: 570px) {
  .maincontent{
    padding-top: 0px;
    margin-top: 0px;
  }
  .introduction {
    margin-top: 80px;
    margin-bottom: 10px;
  }
  .introductionText {
    align-content: center;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .HeaderIntText {
    font-weight: bold;
  }

  .LogoImgIntroduction {
    height: 200px;
  }

  .TechImg {
    height: 60px;
    padding: 5px;
  }
}