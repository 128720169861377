.ChessContainer {
    font-family: 'Aldrich', sans-serif;
    justify-self: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.CopyArea {
    gap: 40px;
    display: flex;
    background-color: rgb(172, 172, 172);
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: space-between;
}

.ContainerChess {
    flex-grow: 1;
    padding-top: 20px;
    min-height: 100vh;
    padding-left: 20px;
    padding-right: 20px;
}

.mt {
    margin-top: 30px;
}

.copy-icon:hover {
    scale: 1.2;
}

.RepoChess {
    max-width: 700px;
}

.RepoChess a {
    color: white;
}

.imgChess {
    width: 700px;
    max-width: 95%;
    height: auto;
}

.imgCHessTEst {
    text-align: center;
}

.arrowleftChess:hover {
    cursor: pointer;
    scale: 1.1;
}

.flex {
    display: flex;
}

.Chessdisplay {
    align-content: center;
}

.gap {
    gap: 40px;
    margin-top: 50px;
}

.FitnessImages {
    width: 100%;
    max-width: 95%;
    height: auto;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
}

.imgFitnessDisplay {
    width: auto;
    height: 270px;
    border-radius: 10px;
}
.imgChessDisplay {
    width: 320px;
    max-width: 48%;
    height: auto;
    border-radius: 10px;
}
.imgFitnessDisplay:hover {
    cursor: pointer;
    scale: 1.1;
}
.text-center {
    display: flex;
    justify-content: space-between;
}

.imgChessDisplay:hover {
    scale: 1.1;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: 10px;
}

@media (max-width: 900px) {

    .FitnessImages {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

}
@media (max-width: 600px) {

    .imgFitnessDisplay {
        height: 150px;
    }

}